import React from 'react';
import { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';

interface ImPropsType {
  userId: string;
}

export default function ImpersonationButton({ userId }: ImPropsType) {
  const { role, adminToken, impersonateAsUser } = useContext(UserContext);

  if (role === 'ADMIN' && adminToken === null && impersonateAsUser) {
    return (
      <a
        href="#"
        className="m-1 btn btn-primary text-white"
        onClick={() => impersonateAsUser(userId)}
      >
        Převtělit
      </a>
    );
  } else {
    return <></>;
  }
}
