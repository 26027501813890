import React, { useState } from 'react';
import { ContainerFormField } from '../../components/common/ContainerFormField';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from 'react-bootstrap';
import { FormField } from '../../components/common/FormField';
import Form from 'react-bootstrap/Form';
import { gql, useMutation } from '@apollo/client';

export interface IAddress {
  addressType: 'MAILING' | 'CONTRACT';
  name: string;
  street: string;
  city: string;
  zip: string;
  state: string;
  phone: string;
  isSameAsMailing?: boolean;
}

interface changeMyAddressResponse {
  changeMyAddress: IAddress;
}

const CHANGE_ADDRESS_MUTATION = gql`
  mutation ChangeMyAddress(
    $addressType: AddressType!
    $name: String
    $street: String
    $city: String
    $zip: String
    $state: String
    $phone: String
    $isSameAsMailing: Boolean
  ) {
    changeMyAddress(
      data: {
        addressType: $addressType
        name: $name
        street: $street
        city: $city
        zip: $zip
        state: $state
        phone: $phone
        isSameAsMailing: $isSameAsMailing
      }
    ) {
      id
      addressType
      name
      street
      city
      zip
      state
      phone
      isSameAsMailing
    }
  }
`;

export default function Address(props: { address: IAddress }) {
  const [address, setAddress] = useState<IAddress>(props.address);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IAddress>({ defaultValues: address, criteriaMode: 'all' });

  const [message, setMessage] = useState<string | null>(null);
  const [messageLevel, setMessageLevel] = useState<string>('success');

  const [showForm, setShowForm] = useState<boolean>(!address.isSameAsMailing);

  const onSubmit: SubmitHandler<IAddress> = async (data) => {
    await changeAddressMutation({
      variables: {
        ...data,
        isSameAsMailing: false,
      },
    });
  };

  const [changeAddressMutation] = useMutation<changeMyAddressResponse>(
    CHANGE_ADDRESS_MUTATION,
    {
      onCompleted: (response) => {
        if (!response.changeMyAddress) {
          setMessage('Chyba při uložení.');
          setMessageLevel('danger');
        } else {
          setMessage('Adresa byla v pořádku uložena.');
          setMessageLevel('success');
          setAddress(response.changeMyAddress);
          reset(response.changeMyAddress);
        }
      },
    },
  );

  const onSameAsMailingChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setShowForm(!event.target.checked);
    if (event.target.checked) {
      await changeAddressMutation({
        variables: {
          addressType: address.addressType,
          isSameAsMailing: true,
        },
      });
    }
  };

  const defaultCols = 6;

  return (
    <>
      {address.addressType === 'CONTRACT' && (
        <Form.Check
          id="isSameAsMailing"
          type="checkbox"
          label="Adresa je stejná jako korespondenční."
          className="mb-2"
          checked={!showForm}
          name="isSameAsMailing"
          onChange={onSameAsMailingChange}
        />
      )}

      {showForm && (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="container">
            <div className="row">
              <div className="form-group col col-12">
                {message && (
                  <div
                    className={`alert alert-${messageLevel} bg-${messageLevel} text-white`}
                    role="alert"
                  >
                    {message}
                  </div>
                )}
              </div>
            </div>
            <ContainerFormField
              name="name"
              type="input"
              label="Jméno/název firmy"
              className={`col-${defaultCols}`}
              required={true}
              errors={errors}
              register={register}
            ></ContainerFormField>
            <ContainerFormField
              name="street"
              type="input"
              label="Ulice, číslo domu"
              className={`col-${defaultCols}`}
              required={true}
              errors={errors}
              register={register}
            ></ContainerFormField>
            <div className="row">
              <div className="form-group col col-2">
                <FormField
                  name="zip"
                  type="input"
                  label="PSČ"
                  required={true}
                  errors={errors}
                  register={register}
                ></FormField>
              </div>
              <div className="form-group col col-4">
                <FormField
                  name="city"
                  type="input"
                  label="Obec"
                  required={true}
                  errors={errors}
                  register={register}
                ></FormField>
              </div>
            </div>
            <ContainerFormField
              name="state"
              type="input"
              label="Stát"
              required={true}
              className={`col-${defaultCols}`}
              errors={errors}
              register={register}
            ></ContainerFormField>
            <ContainerFormField
              name="phone"
              type="input"
              label="Telefonní číslo"
              required={true}
              className={`col-${defaultCols}`}
              errors={errors}
              register={register}
            ></ContainerFormField>
            <div className="row">
              <div className={`form-group col col-12`}>
                <Button type="submit" variant="primary">
                  Uložit
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}
