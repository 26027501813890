import React from 'react';
import ImpersonationButton from '../../components/Impersonation/Impersonation';
import { useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import { gql } from '../../__generated__';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { preparePhotoUrl } from '../../tools';

const GET_USER_DETAIL = gql(`
  query getUserDetail($email: String!) {
    userDetail(email: $email) {
      id
      email
      phone
      role
      legacyId
      adoptions {
        child {
          name
          fullNumber
          mainPhoto
        }
        paymentInterval {
          treaty
        }
        lastPayment
        nextPayment
      }
      mailingAddress {
        name
        street
        city
        zip
        state
        phone
      }
      contractAddress {
        name
        street
        city
        zip
        state
        phone
      }
    }
  }
`);

export default function UserDetail() {
  const { email } = useParams();
  const { loading, error, data } = useQuery(GET_USER_DETAIL, {
    variables: { email: email?.toLowerCase() ?? '' },
  });

  if (loading || !data) return <p>Nahrává se...</p>;
  if (error) return <p>Chyba: {error.message}</p>;

  return (
    <div>
      <Container>
        <Row>
          <Col md={6}>
            <h3>Uživatel {data.userDetail.email}</h3>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Detail</Card.Title>
                <Card.Text>
                  <strong>ID:</strong> {data.userDetail.id}
                </Card.Text>
                <Card.Text>
                  <strong>Email:</strong> {data.userDetail.email}
                </Card.Text>
                <Card.Text>
                  <strong>Telefon:</strong> {data.userDetail.phone}
                </Card.Text>
                <Card.Text>
                  <strong>Role:</strong> {data.userDetail.role}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <h3>Akce</h3>
            <Card className="mb-3">
              <Card.Body>
                <ul className="list-unstyled">
                  <li>
                    <ImpersonationButton
                      userId={data.userDetail.id}
                    ></ImpersonationButton>
                  </li>
                  {data.userDetail.legacyId && (
                    <li>
                      <a
                        className="m-1 btn btn-primary text-white"
                        href={`http://old.pro-contact.cz/cs/account/admin_edit/${data.userDetail.legacyId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Odkaz na starý web
                      </a>
                    </li>
                  )}
                </ul>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <h3>Korespondenční adresa</h3>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>{data.userDetail.mailingAddress?.name}</Card.Title>
                <Card.Text>
                  {data.userDetail.mailingAddress?.street},{' '}
                  {data.userDetail.mailingAddress?.city},{' '}
                  {data.userDetail.mailingAddress?.zip},{' '}
                  {data.userDetail.mailingAddress?.state}
                </Card.Text>
                <Card.Text>
                  <strong>Telefon:</strong>{' '}
                  {data.userDetail.mailingAddress?.phone}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          {data.userDetail.contractAddress?.name && (
            <Col md={6}>
              <h3>Smluvní adresa</h3>
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>
                    {data.userDetail.contractAddress?.name}
                  </Card.Title>
                  <Card.Text>
                    {data.userDetail.contractAddress?.street},{' '}
                    {data.userDetail.contractAddress?.city},{' '}
                    {data.userDetail.contractAddress?.zip},{' '}
                    {data.userDetail.contractAddress?.state}
                  </Card.Text>
                  <Card.Text>
                    <strong>Telefon:</strong>{' '}
                    {data.userDetail.contractAddress?.phone}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>

        <Row>
          <Col md={12}>
            <h3>Adopce</h3>
            {data.userDetail.adoptions.map((adoption, index) => (
              <Card key={index} className="mb-3">
                <Card.Img
                  variant="top"
                  style={{ width: '120px' }}
                  src={preparePhotoUrl(adoption.child.mainPhoto ?? '')}
                  alt={adoption.child.name}
                />
                <Card.Body>
                  <Card.Title>
                    <Link
                      style={{ color: 'darkgreen' }}
                      to={'/child/' + adoption.child.fullNumber}
                    >
                      {adoption.child.name} ({adoption.child.fullNumber})
                    </Link>
                  </Card.Title>
                  <Card.Text>
                    <strong>Způsob platby:</strong>{' '}
                    {adoption.paymentInterval.treaty}
                  </Card.Text>
                  <Card.Text>
                    <strong>Předchozí platba:</strong>{' '}
                    {new Date(adoption.lastPayment).toLocaleDateString()}
                  </Card.Text>
                  <Card.Text>
                    <strong>Následující platba:</strong>{' '}
                    {new Date(adoption.nextPayment).toLocaleDateString()}
                  </Card.Text>
                </Card.Body>
              </Card>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
