import React, { useEffect, useState } from 'react';
import { Button, Form, Offcanvas } from 'react-bootstrap';
import Cookies from 'js-cookie';

export default function CookieConsent() {
  const [show, setShow] = useState(Cookies.get('cookie_consent') === undefined);
  const [showSettings, setShowSettings] = useState(false);

  const handleClose = () => setShow(false);

  const saveCookie = () => {
    Cookies.set('cookie_consent', new Date().toISOString());
    setShow(false);
  };
  useEffect(() => {
    Cookies.get('cookie_consent');
  }, []);
  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="bottom"
        name="bottom"
        backdrop="static"
        className="h-auto"
      >
        <Offcanvas.Header>
          <Offcanvas.Title>Používáme soubory cookies</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ paddingTop: '0' }}>
          {showSettings ? (
            <>
              <h6>Doladění nastavení</h6>
              <p>
                <Form.Check
                  checked
                  disabled
                  type="switch"
                  id="custom-switch"
                  label="Technické cookies"
                />
              </p>
              <p>
                Tyto soubory cookies jsou pro správnou funkci webu
                pro-contact.cz velmi důležité. Bez nich zkrátka nemůžeme web
                pro-contact.cz pořádně fungovat. Abyste mohli využívat kompletní
                služby našeho webu, musí tyto typy cookies zůstat zapnuté.
              </p>
              <Button onClick={saveCookie}>Uložit nastavení</Button>
            </>
          ) : (
            <>
              Cookies jsou drobné soubory, které se při návštěvě webových
              stránek ukládají pro různé účely do zařízení, ze kterého na naše
              stránky vstupujete. Pokud soubory cookies povolíte, proběhne vaše
              návštěva v pořádku dle očekávání.
              <p className="pt-4 text-center">
                <Button onClick={saveCookie}>Povolit vše</Button>{' '}
                <Button onClick={saveCookie} variant="outline-primary">
                  Povolit nezbytné
                </Button>{' '}
                <Button
                  onClick={() => setShowSettings(true)}
                  variant="outline-primary"
                >
                  Upravit mé předvolby
                </Button>
              </p>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
