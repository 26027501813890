import React from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import Pagination from '../../components/Pagination';

export interface IUserAdoption {
  id: string;
  child: {
    name: string;
    fullNumber: string;
  };
}

export interface IUserItem {
  id: string;
  username?: string;
  email: string;
  adoptions: IUserAdoption[];
}

export interface IUserItemNode {
  node: IUserItem;
}

const GET_USERS_LIST = gql`
  query GetUsersList($search: String) {
    usersList(search: $search) {
      edges {
        node {
          id
          email
          username
          adoptions {
            id
            child {
              name
              fullNumber
            }
          }
        }
      }
    }
  }
`;

export default function BackofficeUsersList() {
  const itemsPerPage = 30;

  const { page: currentPage = 1 } = useParams();
  const pageNumber = Number(currentPage) - 1;
  const navigate = useNavigate();
  const [itemOffset, setItemOffset] = useState(pageNumber * itemsPerPage);
  const [searchText, setSearchTest] = useState<string>('');

  const { loading, error, data } = useQuery(GET_USERS_LIST, {
    variables: { search: searchText },
  });

  if (loading)
    return (
      <div>
        <h3>Uživatelé</h3>

        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">
              Vyhledávání
            </span>
          </div>
          <input
            type="text"
            value={searchText}
            onChange={(event) => setSearchTest(event.target.value)}
            className="form-control"
            placeholder="E-mail"
            aria-label="E-mail"
            aria-describedby="basic-addon1"
          />
        </div>
        <h2>Nahrává se...</h2>
      </div>
    );
  if (error) return <p>Chyba: {error.message}</p>;

  const news = data.usersList.edges;
  if (news === null) {
    return <p>Chyba při načítání dat.</p>;
  }

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = news.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(news.length / itemsPerPage);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % news.length;
    setItemOffset(newOffset);
    navigate(`/backoffice/users/page/` + (Number(event.selected) + 1));
  };

  return (
    <div>
      <h3>Uživatelé</h3>

      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="basic-addon1">
            Vyhledávání
          </span>
        </div>
        <input
          type="text"
          value={searchText}
          onChange={(event) => setSearchTest(event.target.value)}
          className="form-control"
          placeholder="E-mail"
          aria-label="E-mail"
          aria-describedby="basic-addon1"
        />
      </div>

      <Pagination
        handlePageClick={handlePageClick}
        pageCount={pageCount}
        pageNumber={pageNumber}
        pageUrlPrefix={`/backoffice/users`}
      ></Pagination>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">E-mail</th>
            <th scope="col">Uživatelské jméno</th>
            <th scope="col">Adopce</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((itemNode: IUserItemNode) => (
            <tr key={itemNode.node.id}>
              <th scope="row">
                <a href={`/backoffice/user/${itemNode.node.email}`}>
                  {itemNode.node.email}
                </a>
              </th>
              <th scope="row">{itemNode.node.username}</th>
              <td>
                {itemNode.node.adoptions.map((adoption: IUserAdoption) => (
                  <span className="badge bg-success m-1" key={adoption.id}>
                    {adoption.child.fullNumber}
                  </span>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pt-2">
        <Pagination
          handlePageClick={handlePageClick}
          pageCount={pageCount}
          pageNumber={pageNumber}
          pageUrlPrefix={`/backoffice/users`}
        ></Pagination>
      </div>
    </div>
  );
}
